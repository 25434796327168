import styles from './styles.module.scss';

export const GoldenTicket = () => {
  return (
    <section aria-label="Golden ticket" className={styles.section}>
      <h2 className={styles.ticket}>GOLDEN TICKET</h2>
      <p className={styles.text}>
        Win one of twenty chances to do a digital meet and greet with a
        ninja!
      </p>
    </section>
  );
};
