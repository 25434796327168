import {
  Header,
  VideoSection,
  About,
  GoldenTicket,
  Footer,
} from 'components';
import {
  CardPacks,
  LearnMore,
  MeetTheNinjas,
  NFTS,
  FAQs,
} from './sections';

function App() {
  return (
    <>
      <Header />
      <VideoSection />
      <About />
      <GoldenTicket />
      <CardPacks />
      <MeetTheNinjas />
      <NFTS />
      {/* <LearnMore /> */}
      <FAQs />
      <Footer />
    </>
  );
}

export default App;
