import styles from './styles.module.scss';
import { TradingCard } from 'components';
import { tradingCardPacks } from 'data';

export const CardPacks = () => {
  return (
    <section
      id="trading-card-packs"
      aria-label="trading card packs"
      className={`${styles.section} ${styles.desktopOnly}`}
    >
      <h3 className={styles.title}>Trading Card Packs</h3>
      {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
      <ul role="list" className={styles.cardsWrapper}>
        {tradingCardPacks.map(
          ({ cardCount, pack, price, isBestDeal }, index) => (
            <li key={index}>
              <TradingCard
                cardCount={cardCount}
                pack={pack}
                price={price}
                isBestDeal={isBestDeal}
              />
            </li>
          ),
        )}
      </ul>
      {/* <div className={styles.walletConnectWrapper}>
        <p className={styles.walletConnectText}>
          Connecting your wallet is easy, let us help you get started.
        </p>
        <button className={styles.walletConnectBtn}>
          Connect your wallet
        </button>
      </div> */}
    </section>
  );
};
