export const faqs = [
  {
    type: 'ninja warrior sport',
    question: 'What are “Ninja Warriors”?',
    answer:
      'Ninja Warriors are elite athletes who compete on extensive obstacle courses.  Television shows, such as American Ninja Warrior/ Sasuke (Japan)/ Ninja Warrior Germany/ etc. have become regular viewing for millions of ninja warrior fans.  There are several noted ninja “leagues” (National Ninja League/ Ultimate Ninja Athletic Association/ ) that hold sanctioned competitions for thousands of ninja warrior athletes throughout the year.',
  },
  {
    type: 'ninja warrior sport',
    question: 'What are some of the most difficult obstacles?',
    answer:
      'Each ninja would give you a different answer… some find that balance obstacles are incredibly difficult, while others find upper-arm obstacles incredibly taxing.  The sport is also evolving very quickly, so many of the obstacles that were impossible a year ago are now common-place for even younger ninjas.',
  },
  {
    type: 'ninja warrior sport',
    question: 'How do I become a Ninja Warrior competitor?',
    answer:
      'The best advice we can give is to find a local ninja gym (yes, that’s a thing!) and become familiar with the sport, the obstacles, and the potential time involved.  At the very least, it’s a phenomenal way to get in shape and stay healthy, at any age!  If you wish to become competitive, you may find yourself training 2-6 hours daily… this is fairly common for most “known” ninjas.',
  },
  {
    type: 'our nft project',
    question: 'Why Ninja Warrior NFTs?',
    answer:
      'We have a direct love for ninja warrior due to our children competing in the sport from very early ages.  Because of this, we have incredible relationships with the best athletes in the sport.  We are also a family of entrepreneurs, in various fields.  We wanted all of these worlds to collide in a way that was a win-win for everyone involved… from the ninjas, to the Cardano community, to the fans of the sport.  It was an absolute no-brainer decision.',
  },
  // {
  //   type: 'our nft project',
  //   question: 'How many Ninja Warrior NFTs will you offer?',
  //   answer:
  //     'In our first series, we will offer 10,000 total NFTs.  These include 5 rarity levels, with the top level being 1-of-1 “Golden Tickets” with special perks.  If the first series sells out, we will offer a second series of mints of the same NFTs, minus the Golden Tickets.  This will be noted in the metadata.',
  // },
  // {
  //   type: 'our nft project',
  //   question: `Aren't your prices a bit high?`,
  //   answer:
  //     'We believe that the content of our NFTs offer great value to the buyer… real-life, professional sports personalities, partnered with incredible artwork.  We took the approach of taking the “traditional” card trading world and applying those thought-processes to our NFTs.  Because of this, we have the ability to reach a wider collecting audience, which creates a greater overall perceived value.',
  // },
  // {
  //   type: 'our nft project',
  //   question: 'Will this be the only series of NFTs you ever offer?',
  //   answer:
  //     'We are already working on the next series of NFTs for a near-future release.  We also have 5-6 other series in the works, as well as brainstorming and creating relationships with other entities and personalities.  We have a LOT of crazy ideas, and we’re here for the long haul.',
  // },
  {
    type: 'our nft project',
    question: 'Why are you using Cardano as your blockchain?',
    answer: `We believe in Cardano, and we put our money where our mouth is.  We are heavily invested in ADA, we are staked with pool operators, and we've even run our own stake pool in the past.  We love that fees are minimal, and we are delighted to be a part of the best blockchain community in the world!  We wanted to release this incredible, inaugural NFT series to promote Cardano/ ADA to those not actively involved in cryptocurrency, and give the Cardano community the notoriety it deserves.`,
  },
  {
    type: 'our nft project',
    question:
      'There are so many Ninja Warriors.  Why did you choose these personalities for your release?',
    answer: `That's a great question, and you're correct… we could have chosen MANY different ninjas for this release!  And in the future, you will definitely see very diverse collections of the best ninjas, at all ages, offered as NFTs.  For this initial release, we wanted to include ambassadors of the sport, easily-recognizable personalities, forward-thinkers in technology and cryptocurrency, and positive role models for younger ninja athletes.  It was an incredible task to narrow it down to 20, but we feel that each of these athletes represent the sport of ninja warrior, as well as the CNFT/ NFT community, at the highest level.`,
  },
  {
    type: 'cardano',
    question: 'What is Cardano?',
    answer: `Cardano is a third generation cryptocurrency based on peer reviewed academic research, and built with rigorous engineering principles. It's a 'Proof of Stake' blockchain which means it is much more energy efficient than Proof of Work blockchains like Bitcoin.`,
  },
];
