export const ninjaAccomplishments = [
  {
    name: 'Daniel Gil',
    accomplishments: [
      'American Ninja Warrior Season 12, Champion',
      '6x American Ninja Warrior, 6x National Finalist',
      '2x 18-foot Mega Wall',
      'ANW USA Vs The World - Team USA',
      'ANW Indonesia Vx The World - Team USA',
      'ANW: Ninja Vs Ninja - Team “Iron Grip”, Captain',
      'ANW All-Stars - Team “Akbar”',
    ],
    socialLinks: {
      youtube: 'https://www.youtube.com/channel/UCCX6JhlDYLGCzNs5ieBWwPA',
      twitter: '',
      instagram: 'https://www.instagram.com/kingdom_ninja/',
      facebook: '',
      tiktok: '',
    },
  },
  {
    name: 'Tyler Gillett',
    accomplishments: [
      '5x American Ninja Warrior, 5x National Finalist',
      'ANW: Ninja Vs Ninja - Team “Young Bloods”',
    ],
    socialLinks: {
      youtube: 'https://www.youtube.com/c/tylergillett1',
      twitter: '',
      instagram: 'https://www.instagram.com/tylercgillett/',
      facebook: '',
      tiktok: 'https://www.tiktok.com/@tylercgillett',
    },
  },
  {
    name: 'RJ Roman',
    accomplishments: [
      'Season 14 American Ninja Warrior',
      '5x American Ninja Warrior, 4x National Finalist',
    ],
    socialLinks: {
      youtube: '',
      twitter: '',
      instagram: 'https://www.instagram.com/rjroams/',
      facebook: '',
      tiktok: '',
    },
  },
  {
    name: 'Karsten Williams',
    accomplishments: [
      '8x American Ninja Warrior, 6x National Finalist',
      'ANW All-Stars Skills Challenge 2017, 2020 - Salmon Ladder Champion',
      'ANW All-Stars 2020 Team Matt Iseman, Champion',
      'ANW: Ninja Vs Ninja - Team “Fast Cats”, 2x Captain',
    ],
    socialLinks: {
      youtube: 'https://www.youtube.com/channel/UC-wOdeofvKwTxSSIi2NbUyA',
      twitter: 'https://twitter.com/RealTeamKarsten',
      instagram: 'https://www.instagram.com/teamkarsten/',
      facebook: '',
      tiktok: '',
    },
  },
  {
    name: 'Maggi Thorne',
    accomplishments: [
      'Season 14 American Ninja Warrior',
      '7x American Ninja Warrior, 1x National Finalist',
      'ANW: Ninja Vs Ninja - Team “The Expendabulls”',
      'ANW: Ninja Vs Ninja - Team “Grit”',
      '2x NBC Spartan Ultimate Team Challenge',
    ],
    socialLinks: {
      youtube: '',
      twitter: 'https://twitter.com/Nvr_GvUp',
      instagram: 'https://www.instagram.com/nvr_gvup/',
      facebook: '',
      tiktok: 'https://www.tiktok.com/@maggithorne',
    },
  },
  {
    name: 'Elijah Browning',
    accomplishments: [
      'Season 14 American Ninja Warrior',
      'American Ninja Warrior Season 13,  National Finalist (9th place)',
      'American Ninja Warrior All-Star Competitor',
      'American Ninja Warrior Jr. Season 1 - Top 16',
      '2019 UNAA 13u Champion',
      '2019 State Games of America 13u Ninja Champion',
    ],
    socialLinks: {
      youtube: 'https://www.youtube.com/channel/UCMltrua-2cSvO0GvB_0aoNw',
      twitter: 'https://twitter.com/TheBOSSPool',
      instagram: 'https://www.instagram.com/elijahthebossninja/',
      facebook: '',
      tiktok: '',
    },
  },
  {
    name: 'Kaden Lebsack',
    accomplishments: [
      'Season 14 American Ninja Warrior',
      'American Ninja Warrior Season 13, Last Man Standing',
      'ANW First ever rookie to make Stage 4, Las Vegas Finals',
      'American Ninja Warrior Jr. Season 2, 2nd Place',
      '2020 UNX Championship Series, 3rd Place',
      '3x UBW Grand Champion',
      '3x UNAA World Finals Champion',
      'Next Level Ninja Games, 2nd Place',
    ],
    socialLinks: {
      youtube: 'https://www.youtube.com/channel/UCas5aKpsWEdR5x6nDVy3ZVA',
      twitter: '',
      instagram: 'https://www.instagram.com/kadenwake/',
      facebook: '',
      tiktok: '',
    },
  },
  {
    name: 'Sam Folsom',
    accomplishments: [
      '2020 UNX Championship Series, Champion',
      'American Ninja Warrior Season 13',
    ],
    socialLinks: {
      youtube: '',
      twitter: '',
      instagram: 'https://www.instagram.com/th3_oneand_only/',
      facebook: '',
      tiktok: 'https://www.tiktok.com/@samfolsom',
    },
  },
  {
    name: 'Christian Youst',
    accomplishments: [
      'Season 14 American Ninja Warrior',
      'American Ninja Warrior Season 13, National Finalist',
      'National Ninja League - World Champion',
      `National Ninja League - World's Strongest Ninja`,
      'World Ninja Athlete Games - World Champion',
      'World Ninja Challenge - World Champion',
      'State Games of America - National Champion',
      'Texas Ninja League - State Champion',
    ],
    socialLinks: {
      youtube: '',
      twitter: '',
      instagram: 'https://www.instagram.com/team_youst_ninja/',
      facebook: '',
      tiktok: 'https://www.tiktok.com/@christian_youst',
    },
  },
  {
    name: 'Lance Pekus',
    accomplishments: [
      'Season 14 American Ninja Warrior',
      '10x American Ninja Warrior, 6x National Finalist',
      '3x ANW All-Stars',
      '3x Team Ninja Warrior Captain',
      '2x Spartan Ultimate Team Challenge',
    ],
    socialLinks: {
      youtube: 'https://www.youtube.com/channel/UC4g9As9oPAoAWKZWEIiCWxw',
      twitter: 'https://twitter.com/lancepekus',
      instagram: 'https://www.instagram.com/lancepekus/',
      facebook: 'https://www.facebook.com/lancepekus',
      tiktok: '',
    },
  },
  {
    name: 'Jake Murray',
    accomplishments: [
      '7x American Ninja Warrior, 5x National Finalist',
      'ANW: Ninja Vs Ninja - Team “Party Time”',
      'Team Ninja Warrior Champion',
    ],
    socialLinks: {
      youtube: '',
      twitter: '',
      instagram: 'https://www.instagram.com/jake.murray/',
      facebook: '',
      tiktok: 'https://www.tiktok.com/@nechtology',
    },
  },
  {
    name: 'Nate Burkhalter',
    accomplishments: [
      '7x American Ninja Warrior, 3x National Finalist',
      '"Exatlon USA" TV Show, Champion',
    ],
    socialLinks: {
      youtube: 'https://www.youtube.com/c/NateBurkhalterNoLimitsNinja',
      twitter: 'https://twitter.com/NoLimitsNate_',
      instagram: 'https://www.instagram.com/nolimitsnate_/',
      facebook: '',
      tiktok: '',
    },
  },
  {
    name: 'Isabella Wakeham',
    accomplishments: [
      'Season 14 American Ninja Warrior',
      'American Ninja Warrior Season 13, National Finalist',
      'American Ninja Warrior All-Star Competitor',
      'American Ninja Warrior Jr. Season 1',
      'National Ninja League - Elite World Champion 2x',
    ],
    socialLinks: {
      youtube: '',
      twitter: '',
      instagram: 'https://www.instagram.com/isabella.ninja/',
      facebook: '',
      tiktok: '',
    },
  },
  {
    name: 'Isaiah Thomas',
    accomplishments: [
      'Season 14 American Ninja Warrior',
      'American Ninja Warrior Season 13 - National Finalist (12th Place)',
      'American Ninja Warrior Jr. Season 2 - Top 8',
      '2021 UNAA 15u - World Champion',
      '2021 TNL 15u - State Champion',
      '2022 NNL Elite - 3rd Place',
    ],
    socialLinks: {
      youtube: '',
      twitter: '',
      instagram: 'https://www.instagram.com/flyboyyninja/',
      facebook: '',
      tiktok: '',
    },
  },
  {
    name: 'Jody Avila',
    accomplishments: [
      'Season 14 American Ninja Warrior',
      '7x American Ninja Warrior, 5x National Finalist',
    ],
    socialLinks: {
      youtube: '',
      twitter: '',
      instagram: 'https://www.instagram.com/jodyavila.ninja/',
      facebook: '',
      tiktok: '',
    },
  },
  {
    name: 'Nate Hansen',
    accomplishments: [
      'Season 14 American Ninja Warrior',
      'American Ninja Warrior 3x, 2013 National Finalist',
    ],
    socialLinks: {
      youtube: '',
      twitter: '',
      instagram: 'https://www.instagram.com/gnarlyninjanate/',
      facebook: '',
      tiktok: '',
    },
  },
  {
    name: 'Taylor Greene',
    accomplishments: [
      'American Ninja Warrior Junior Seasons 1-3',
      'ANW Jr. Season 1 “Last Girl Standing” (4th Place)',
      'ANW Jr. Season 3 “Last Girl Standing” (3rd Place)',
      '2021 UNAA Pro Female Champion',
      '2019 & 2020 Colorado Ninja League Pro Female Champion',
      '2022 Ninja Sport Network Female Champion',
      'Wolfpack Ninja Tour Youth Female Champion',
    ],
    socialLinks: {
      youtube: 'https://www.youtube.com/c/TaylorGreeneninja',
      twitter: '',
      instagram: 'https://www.instagram.com/sweetninjagirl/',
      facebook: '',
      tiktok: '',
    },
  },
  {
    name: 'Kyle Soderman',
    accomplishments: [
      'Season 14 American Ninja Warrior',
      '3x American Ninja Warrior, National Finalist',
      'ANW: Ninja Vs Ninja',
      'World Chase Tag HF Captain',
      'World Chase Tag MVP',
      'Ultimate Beastmaster Season 2',
    ],
    socialLinks: {
      youtube: '',
      twitter: 'https://twitter.com/MinneSoderman',
      instagram: 'https://www.instagram.com/minnesoderman/',
      facebook: '',
      tiktok: 'https://www.tiktok.com/@kylesoderman',
    },
  },
  {
    name: 'Jamie Rahn',
    accomplishments: [
      'Season 14 American Ninja Warrior',
      '12x American Ninja Warrior, 7x National Finalist',
      'Team Ninja Warrior',
      'ANW: Ninja vs Ninja',
      'ANW All-Stars, ANW Skills',
      'Sasuke Vietnam, Sasuke Vietnam All-Stars',
      'Sasuke Japan',
      'Ninja Warrior Germany, Ninja Warrior Germany “4 Nations” Special',
    ],
    socialLinks: {
      youtube: 'https://www.youtube.com/user/addictedtotrill',
      twitter: 'https://twitter.com/CaptainNBC',
      instagram: 'https://www.instagram.com/jamierahn/',
      facebook: 'https://www.facebook.com/JamieRahnNinjaWarrior',
      tiktok: '',
    },
  },
  {
    name: 'Tiana Webberley',
    accomplishments: [
      'Season 14 American Ninja Warrior',
      '7x American Ninja Warrior, 4x National Finalist',
      'ANW: Ninja Vs Ninja - Team “Iron Grip”',
      '2020 NNL Champion',
      'NCAA Division 1 Pole Vaulter',
    ],
    socialLinks: {
      youtube: '',
      twitter: 'https://twitter.com/SweetTee_02',
      instagram: 'https://www.instagram.com/sweett_02/',
      facebook: '',
      tiktok: '',
    },
  },
];
