import { useRef, useState } from 'react';
import styles from './styles.module.scss';

import { MeetNinjaCard } from 'components';
import { ninjas } from 'data';

export const MeetTheNinjas = () => {
  const [showBioAtIndex, setShowBioAtIndex] = useState(-1);
  const scrollDiv = useRef();
  const scrollWidth =
    document.getElementsByTagName('html')[0].clientWidth > 1400
      ? 1148
      : 280;

  const handleScrollForward = () => {
    scrollDiv.current.scrollBy({ left: scrollWidth, behavior: 'smooth' });
  };

  const handleScrollBackward = () => {
    scrollDiv.current.scrollBy({ left: -scrollWidth, behavior: 'smooth' });
  };

  return (
    <section id="meet-the-ninjas" className={styles.section}>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>MEET THE NINJAS</h2>
        <div ref={scrollDiv} className={styles.ninjaCardWrapper}>
          {ninjas.map((ninja, index) => (
            <MeetNinjaCard
              key={ninja.imgSrc}
              name={ninja.name}
              imgSrc={ninja.imgSrc}
              index={index}
              isBioOpen={showBioAtIndex === index}
              bioStatusHandler={setShowBioAtIndex}
            />
          ))}
        </div>
        <div className={styles.seeMore}>
          <button
            onClick={handleScrollBackward}
            className={`${styles.button} ${styles.backwards}`}
          >
            {'<'}
          </button>
          <h5>
            SEE MORE NINJAS{' '}
            <button
              onClick={handleScrollForward}
              className={`${styles.button} ${styles.forwards}`}
            >
              {'>'}
            </button>
          </h5>
        </div>
      </div>
    </section>
  );
};
