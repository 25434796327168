import styles from './styles.module.scss';

export const Header = () => {
  return (
    <header className={styles.header}>
      <div className={styles.headerInnerContainer}>
        <h1 className={styles.logo}>
          <img
            src="/assets/lnw-logo.png"
            alt="Logo for League of Ninja Warriors"
          />
        </h1>
        <nav className={styles.nav}>
          {/* https://www.scottohara.me/blog/2019/01/12/lists-and-safari.html */}
          {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
          <ul role="list" className={styles.navlist}>
            <li>
              <a href="#trading-card-packs" className={styles.desktopOnly}>
                Buy Now
              </a>
            </li>
            <li>
              <a href="#meet-the-ninjas">Ninjas</a>
            </li>
            <li>
              <a href="#nfts">NFTs</a>
            </li>
            <li>
              <a href="#FAQ">FAQ</a>
            </li>
            {/* <li>
              <button>Connect Wallet</button>
            </li> */}
          </ul>
        </nav>
      </div>
    </header>
  );
};
