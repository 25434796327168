import styles from './styles.module.scss';

export const About = () => {
  return (
    <section aria-label="About" className={styles.section}>
      <div className={styles.logoContainer}>
        <h2 className={styles.logo}>
          <img
            src="/assets/lnw-logo.png"
            alt="Logo for League of Ninja Warriors"
          />
        </h2>
      </div>
      <div className={styles.aboutContainer}>
        <h3 className={styles.aboutTitle}>
          HIGHLY COLLECTABLE NFTS FROM SOME OF THE WORLD'S MOST FAMOUS
          NINJA WARRIORS!
        </h3>
        <p className={styles.aboutText}>
          Whether crushing mind-blowing obstacles on television, or winning
          championships in worldwide competitions and events, these ninjas
          deliver excellence, in both performance and with their striking
          NFTs. Become a part of the ninja warrior family and collect yours
          today, exclusively on the Cardano platform!
        </p>
        <span className={styles.launchState}>COMING SOON!</span>
      </div>
    </section>
  );
};
