import styles from './styles.module.scss';

export const NFTType = ({ title, src, alt, rarity }) => {
  return (
    <div className={styles.cell}>
      <div className={styles.dataWrapper}>
        <div className={styles.titleWrapper}>
          <h5 className={styles.title}>{title}</h5>
        </div>
        <div className={styles.pieChartWrapper}>
          <div
            className={styles.pieChart}
            style={{ '--b': '0.75rem', '--p': `${rarity}` }}
          >
            <h5 className={styles.rarityText}>
              {rarity}
              <span>%</span>
            </h5>
          </div>
        </div>
      </div>
      <div className={styles.imageWrapper}>
        {src.slice(src.length - 3) === 'jpg' ? (
          <img src={src} alt={alt} />
        ) : (
          <video src={src} alt={alt} muted autoPlay loop />
        )}
      </div>
    </div>
  );
};
