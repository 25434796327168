import styles from './styles.module.scss';

export const GoldenTicketNFTType = ({
  title,
  src,
  alt,
  rarity,
  className,
}) => {
  return (
    <div className={`${styles.cell} ${className}`}>
      <div className={styles.wrapper}>
        <div
          className={styles.pieChart}
          style={{ '--b': '0.75rem', '--p': `${rarity}` }}
        >
          <h5 className={styles.rarityText}>
            {rarity}
            <span>%</span>
          </h5>
        </div>
        <h5 className={styles.title}>
          {title}
          {/* <br />
          Meet {'&'} Greet */}
        </h5>
      </div>
      <div className={styles.mediaWrapper}>
        <div className={styles.ticket}>
          <img
            src={'./assets/playButton.png'}
            alt="Play Button"
            className={styles.playButton}
          />
        </div>
        <video
          className={styles.video}
          src={src}
          alt={alt}
          autoPlay
          muted
          loop
        />
      </div>
      <div className={styles.limited}>Only 20 available!</div>
    </div>
  );
};
