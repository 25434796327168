export const tradingCardPacks = [
  {
    cardCount: 1,
    pack: 'single pack',
    price: 65,
    isBestDeal: false,
  },
  {
    cardCount: 3,
    pack: 'multipack',
    price: 185,
    isBestDeal: false,
  },
  {
    cardCount: 6,
    pack: 'multipack',
    price: 302,
    isBestDeal: false,
  },
  {
    cardCount: 10,
    pack: 'multipack',
    price: 585,
    isBestDeal: true,
  },
];
