import { GoldenTicketNFTType, NFTType } from 'components';
import styles from './styles.module.scss';
import { nftVarieties } from 'data';

export const NFTS = () => {
  return (
    <section id="nfts" className={styles.section}>
      <div className={styles.wrapper}>
        <div className={styles.box}>
          <h4 className={styles.title}>
            FIVE DIFFERENT VARIETIES OF NFTS
          </h4>
          <div className={styles.grid}>
            {nftVarieties.map(({ title, src, alt, rarity }) =>
              title === 'Golden Ticket' ? (
                <GoldenTicketNFTType
                  key={title}
                  title={title}
                  src={src}
                  alt={alt}
                  rarity={rarity}
                  className={styles.spanRows}
                />
              ) : (
                <NFTType
                  key={title}
                  title={title}
                  src={src}
                  alt={alt}
                  rarity={rarity}
                />
              ),
            )}
          </div>
          <h4 className={`${styles.title} ${styles.desktopOnly}`}>
            YOUR NFTS
          </h4>
          <div
            className={`${styles.title} ${styles.yourNFTs} ${styles.desktopOnly}`}
          >
            Coming Soon!
          </div>
        </div>
      </div>
    </section>
  );
};
