import { motion } from 'framer-motion';
import { NinjaBio } from 'components';
import styles from './styles.module.scss';

export const MeetNinjaCard = ({
  name,
  imgSrc,
  index,
  isBioOpen,
  bioStatusHandler,
}) => {
  return (
    <>
      <div className={styles.cardWrapper}>
        <motion.div layoutId={`card ${name}`} className={styles.card}>
          <motion.img
            layoutId={`image ${name}`}
            className={styles.image}
            src={imgSrc}
          />
          <button
            className={styles.button}
            onClick={() => {
              bioStatusHandler(index);
              document.body.style.overflow = 'hidden';
            }}
          >
            +
          </button>
          <h4 className={styles.name}>{name.toUpperCase()}</h4>
        </motion.div>
      </div>
      {isBioOpen && (
        <NinjaBio
          layoutIdSuffix={`${name}`}
          name={name}
          imgSrc={imgSrc}
          hideBio={() => {
            bioStatusHandler(-1);
            document.body.style.overflow = 'auto';
          }}
          showNextBio={() => bioStatusHandler((index + 1) % 20)}
        />
      )}
    </>
  );
};
