export const ninjas = [
  {
    name: 'Daniel Gil',
    imgSrc: './assets/ninjas/01-Daniel-Gil.jpg',
  },
  {
    name: 'Tyler Gillett',
    imgSrc: './assets/ninjas/02-Tyler-Gillett.jpg',
  },
  {
    name: 'RJ Roman',
    imgSrc: './assets/ninjas/03-RJ-Roman.jpg',
  },
  {
    name: 'Karsten Williams',
    imgSrc: './assets/ninjas/04-Karsten-Williams.jpg',
  },
  {
    name: 'Maggi Thorne',
    imgSrc: './assets/ninjas/05-Maggi-Thorne.jpg',
  },
  {
    name: 'Elijah Browning',
    imgSrc: './assets/ninjas/06-Elijah-Browning.jpg',
  },
  {
    name: 'Kaden Lebsack',
    imgSrc: './assets/ninjas/07-Kaden-Lebsack.jpg',
  },
  {
    name: 'Sam Folsom',
    imgSrc: './assets/ninjas/08-Sam-Folsom.jpg',
  },
  {
    name: 'Christian Youst',
    imgSrc: './assets/ninjas/09-Christian-Youst.jpg',
  },
  {
    name: 'Lance Pekus',
    imgSrc: './assets/ninjas/10-Lance-Pekus.jpg',
  },
  {
    name: 'Jake Murray',
    imgSrc: './assets/ninjas/11-Jake-Murray.jpg',
  },
  {
    name: 'Nate Burkhalter',
    imgSrc: './assets/ninjas/12-Nate-Burkhalter.jpg',
  },
  {
    name: 'Isabella Wakeham',
    imgSrc: './assets/ninjas/13-Isabella-Wakeham.jpg',
  },
  {
    name: 'Isaiah Thomas',
    imgSrc: './assets/ninjas/14-Isaiah-Thomas.jpg',
  },
  {
    name: 'Jody Avila',
    imgSrc: './assets/ninjas/15-Jody-Avila.jpg',
  },
  {
    name: 'Nate Hansen',
    imgSrc: './assets/ninjas/16-Nate-Hansen.jpg',
  },
  {
    name: 'Taylor Greene',
    imgSrc: './assets/ninjas/17-Taylor-Greene.jpg',
  },
  {
    name: 'Kyle Soderman',
    imgSrc: './assets/ninjas/18-Kyle-Soderman.jpg',
  },
  {
    name: 'Jamie Rahn',
    imgSrc: './assets/ninjas/19-Jamie-Rahn.jpg',
  },
  {
    name: 'Tiana Webberley',
    imgSrc: './assets/ninjas/20-Tiana-Webberley.jpg',
  },
];
