import { useRef, useEffect, useState, useCallback } from 'react';
import { FAQAccordian } from 'components';
import styles from './styles.module.scss';

export const FAQList = ({ faqsList, currentFaqsType }) => {
  const collapse = useRef();
  const [maxHeight, setMaxHeight] = useState(0);
  const [faqsHeight, setFaqsHeight] = useState(0);

  const updateHeight = useCallback(
    (changeInHeight = 0) => {
      if (maxHeight !== 0) setMaxHeight(m => m + changeInHeight);
      else setMaxHeight(faqsHeight);
    },
    [faqsHeight, maxHeight],
  );

  useEffect(() => {
    if (collapse.current) {
      if (faqsList[0].type !== currentFaqsType) setMaxHeight(0);
      else updateHeight();
    }
  }, [faqsList, currentFaqsType, updateHeight]);

  useEffect(() => {
    if (collapse.current) setFaqsHeight(collapse.current.scrollHeight);
  }, [setFaqsHeight, currentFaqsType]);

  return (
    <div
      ref={collapse}
      className={styles.faqWrapper}
      style={{ maxHeight: maxHeight }}
    >
      {faqsList.map(({ question, answer }, index) => (
        <FAQAccordian
          key={index}
          question={question}
          answer={answer}
          updateContainerHeight={updateHeight}
        />
      ))}
    </div>
  );
};
