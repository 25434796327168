import styles from './styles.module.scss';

export const VideoSection = () => {
  return (
    <video
      className={styles.video}
      src="/assets/lnw-video.mp4"
      alt="Video of Ninja Warriors in action."
      muted
      autoPlay
      loop
    />
  );
};
