import styles from './styles.module.scss';

export const TradingCard = ({ cardCount, pack, price, isBestDeal }) => {
  return (
    <article className={`${styles.card} ${isBestDeal ? styles.deal : ''}`}>
      {isBestDeal ? (
        <span className={styles.dealText}>Best deal!</span>
      ) : null}
      <h3 className={styles.heading}>
        {cardCount} card <br /> {pack}
      </h3>
      <p className={styles.info}>
        Includes {cardCount} NFT trading card{cardCount > 1 ? 's' : ''} of
        various rarity
      </p>
      {/* <span className={styles.priceText}>Price</span> */}
      {/* <span className={styles.priceAmount}>{price} ADA</span> */}
      <button className={styles.cta}>Coming Soon</button>
    </article>
  );
};
