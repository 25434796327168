import { useState, useEffect, useRef } from 'react';
import styles from './styles.module.scss';

export const FAQAccordian = ({
  question,
  answer,
  updateContainerHeight,
}) => {
  const [icon, setIcon] = useState('\u25bc');
  const [maxHeight, setMaxHeight] = useState(0);
  const [faqHeight, setFaqHeight] = useState(null);

  const collapse = useRef();

  const handleClick = () => {
    if (maxHeight !== 0) {
      setMaxHeight(0);
      setIcon('\u25bc');
      updateContainerHeight(-faqHeight);
    } else {
      setMaxHeight(faqHeight);
      setIcon('\u25b2');
      updateContainerHeight(faqHeight);
    }
  };

  useEffect(() => {
    if (collapse.current) setFaqHeight(collapse.current.scrollHeight);
  }, [setFaqHeight]);

  return (
    <div className={styles.faqWrapper}>
      <button onClick={handleClick} className={styles.button}>
        {icon}
      </button>
      <div>
        <h4 className={styles.question}>{question}</h4>
        <p
          ref={collapse}
          className={styles.answer}
          style={{ maxHeight: maxHeight + 'px' }}
        >
          {answer}
        </p>
      </div>
    </div>
  );
};
