export const nftVarieties = [
  {
    title: 'Strong Starter',
    src: './assets/nft-varieties/StrongStarterVarietyCard.jpg',
    alt: 'Strong Starter NFT',
    rarity: '50',
  },
  {
    title: 'Beta Breaker',
    src: './assets/nft-varieties/BetaBreakerVarietyCard.jpg',
    alt: 'Beta Breaker NFT',
    rarity: '30',
  },
  {
    title: 'Golden Ticket',
    src: './assets/nft-varieties/GoldenTicketVarietyCard.mp4',
    alt: 'Golden Ticket NFT',
    rarity: '0.2',
  },
  {
    title: 'Course Crusher',
    src: './assets/nft-varieties/CourseCrusherVarietyCard.jpg',
    alt: 'Course Crusher NFT',
    rarity: '15',
  },
  {
    title: 'Buzzer Beater',
    src: './assets/nft-varieties/BuzzerBeaterVarietyCard.mp4',
    alt: 'Buzzer Beater NFT',
    rarity: '4.8',
  },
];
