import { FAQList } from 'components';
import { useState } from 'react';
import styles from './styles.module.scss';
import { faqs } from 'data';

const faqTypes = [...new Set(faqs.map(({ type }) => type))];

export const FAQs = () => {
  const [faqFilter, setFaqFilter] = useState('ninja warrior sport');

  return (
    <section id="FAQ" className={styles.section}>
      <aside className={styles.faqFilterContainter}>
        <div className={styles.filterButtonsWrapper}>
          <button
            className={`${styles.button} ${
              faqFilter === 'ninja warrior sport' ? styles.active : ''
            }`}
            onClick={() => setFaqFilter('ninja warrior sport')}
          >
            Ninja Warrior Sport
          </button>
          <button
            className={`${styles.button} ${
              faqFilter === 'our nft project' ? styles.active : ''
            }`}
            onClick={() => setFaqFilter('our nft project')}
          >
            Our NFT Project
          </button>
          <button
            className={`${styles.button} ${
              faqFilter === 'cardano' ? styles.active : ''
            }`}
            onClick={() => setFaqFilter('cardano')}
          >
            Cardano
          </button>
        </div>
      </aside>
      <article className={styles.faqContainer}>
        <h3 className={styles.title}>HAVE ANY QUESTIONS?</h3>
        {faqTypes.map(type => (
          <FAQList
            key={type}
            faqsList={faqs.filter(faq => faq.type === type)}
            currentFaqsType={faqFilter}
          />
        ))}
      </article>
    </section>
  );
};
